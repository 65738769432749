import React, { useState, useEffect } from "react"
import {Helmet} from "react-helmet";
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { injectIntl, FormattedMessage } from "gatsby-plugin-react-intl";

const Moodboard = ({intl}) => {

  // Client-side Runtime Data Fetching
  const [builderHTML, setbuilderHTML] = useState(null);
  const [accessCode, setaccessCode] = useState("");
  const [formVisibility, setformVisibility] = useState("flex");
  const [accessCodeStatusVisibility, setaccessCodeStatusVisibility] = useState("none");
  const [statusTextL1, setstatusTextL1] = useState("");
  const [statusTextL2, setstatusTextL2] = useState("");

  const handleSubmit = e => {
    e.preventDefault()
    if(accessCode != "") {
      fetchData()
    }
  }

  async function fetchData() {
    fetch(`/api/builderIO?accessCode=${accessCode}`)
    .then( async (response) => {

      // get json response here
      let responseData = await response.json();
      
      if(response.status === 200){
        //console.log(response);
        setbuilderHTML(responseData);
        setformVisibility("none");
      }else{
       // Rest of status codes (400,500,303), can be handled here appropriately
       if(response.status === 401){
        setstatusTextL1("unknown access code");
        setstatusTextL2("please try again");
        setaccessCodeStatusVisibility("block");
       }
      }

    })
    .catch((err) => {
        setstatusTextL1("error");
        setstatusTextL2("moodboard might be unpublished");
        setaccessCodeStatusVisibility("block");
        //console.log(err);
    })
  }

  useEffect(() => {
    if(accessCode != "") {
      fetchData()
    }
  }, []);

  return(

    <Layout>
      <Helmet><script src="https://cdn.builder.io/js/webcomponents"></script></Helmet>
      <SEO title={intl.formatMessage({ id: "titleMoodboard" })} lang={intl.locale} />
      <div className="accessCodeContainer" style={{display: formVisibility}}>
        <form className="moodboardAccessCode" onSubmit={handleSubmit}>
          <div className="labelAccessCode">Please enter your access code</div>
            <input
              className="accessCodeInput"
              type="password"
              name="accessCode"
              value={accessCode}
              onChange={e => {
                setaccessCode(e.target.value);
                setaccessCodeStatusVisibility("none");
              }}
            />
          <button className="accessCodeSubmit" type="submit">Submit</button>
          <div className="labelAccessCodeStatus" style={{display: accessCodeStatusVisibility}}>
            {statusTextL1}
            <br/>
            {statusTextL2}
          </div>
        </form>
      </div>
      <div dangerouslySetInnerHTML={{ __html: builderHTML }} className="moodboardContainer"></div>
    </Layout>

  )
}

export default injectIntl(Moodboard)